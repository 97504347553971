// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context('../images', true)

require('@rails/ujs').start()
require("jquery")

import "bootstrap"

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')
require('@uppy/webcam/dist/style.css')
require('@uppy/image-editor/dist/style.css')

//require('bootstrap-datepicker')
require('hideshowpassword')
require("./filterrific-jquery.js");

